/* eslint-disable prefer-promise-reject-errors */
const users = [
  { login: 'teste', name: 'Djoni', enabled: true, password: '123456' }
];

const permissions = [
  'EDITAR_TRANSCRICAO',
  'CRIAR_TRANSCRICAO',
  'CRIAR_USUARIO'
];

const transcriptions = [
  {
    _id: '60880ec12bc48a0010b5a28f',
    metadata: {
      fileName: 'boa noite.mp4',
      hash: 'd41d8cd98f00b204e9800998ecf8427e',
      modifiedTime: '2021-04-27T10:17:24',
      multiChannel: false,
      name: 'teste',
      pageNumber: 0,
      revision: 'automatic',
      status: 'completed',
      sync: 'LOCAL',
      transcriptionDate: '2021-04-27T10:16:33',
      video: {}
    },
    transcription: {
      parts: [
        {
          align: 1,
          cpuTime: 0,
          diarization: true,
          displayPosition: 0,
          displaytext: '',
          hasChanges: false,
          needResync: false,
          pos: 0,
          speaker: '',
          type: 'FreeText'
        },
        {
          _id: '5c7ad2da-a45b-4698-8178-77ac1fcdb82d',
          align: 1,
          begin: 0,
          channel: 0,
          cpuTime: 1.0268568992614746,
          diarization: true,
          displayFormat: {
            textFormats: [
              {
                fontColor: '#000000',
                fontFamily: 'Arial',
                fontSize: 15,
                format: 56,
                length: 35,
                paragraph: 256,
                start: 0,
                typographicalList: 6729776
              }
            ]
          },
          displayPosition: 1,
          displaytext: 'Queria dizer só uma coisa eu quero.',
          end: 2.21,
          hasChanges: false,
          needResync: false,
          pos: 1,
          speaker: '',
          type: 'Text',
          words: [
            {
              begin: 0,
              end: 0.24,
              pos: 0,
              score: 0.9932933449745178,
              word: 'queria'
            },
            {
              begin: 0.24010136604309082,
              end: 0.44999999999999996,
              pos: 7,
              score: 1,
              word: 'dizer'
            },
            {
              begin: 0.45056920051574706,
              end: 0.6899220085144042,
              pos: 13,
              score: 0.9947465062141418,
              word: 'só'
            },
            {
              begin: 0.6899220085144042,
              end: 0.8999999999999999,
              pos: 16,
              score: 1,
              word: 'uma'
            },
            {
              begin: 0.8999999999999999,
              end: 1.3496732711791992,
              pos: 20,
              score: 1,
              word: 'coisa'
            },
            {
              begin: 1.38,
              end: 1.4999914169311523,
              pos: 26,
              score: 0.40391266345977783,
              word: 'eu'
            },
            {
              begin: 1.50005069732666,
              end: 1.8299098205566406,
              pos: 29,
              score: 0.3475542366504669,
              word: 'quero'
            }
          ]
        },
        {
          _id: 'a111ce70-fb81-4a4e-917c-da95f0c1d499',
          align: 1,
          begin: 3.46375,
          channel: 0,
          cpuTime: 0.5409440994262695,
          diarization: true,
          displayFormat: {
            textFormats: [
              {
                fontColor: '#000000',
                fontFamily: 'Arial',
                fontSize: 15,
                format: 56,
                length: 10,
                paragraph: 256,
                start: 0,
                typographicalList: 6729776
              }
            ]
          },
          displayPosition: 2,
          displaytext: 'Boa noite.',
          end: 6.17375,
          hasChanges: false,
          needResync: false,
          pos: 37,
          speaker: '',
          type: 'Text',
          words: [
            {
              begin: 3.82375,
              end: 4.09375,
              pos: 0,
              score: 1,
              word: 'boa'
            },
            {
              begin: 4.09375,
              end: 4.453682594299316,
              pos: 4,
              score: 1,
              word: 'noite'
            }
          ]
        }
      ],
      quality: 'inconclusive',
      qualityCounts: {
        good: 7,
        medium: 0,
        poor: 2
      }
    }
  }
];

export function configureBackend () {
  const realFetch = window.fetch;
  console.log('Fake');
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // authenticate ---------------------------------------------------------------------------
        if (url.endsWith('/api/auth/login') && opts.method === 'POST') {
          const params = JSON.parse(opts.body);

          const filteredUsers = users.filter(user => {
            return user.login === params.login;
          });

          const responseJson = {
            username: filteredUsers[0].login,
            token: 'jwt-token'
          };

          resolve({
            ok: true,
            text: () => Promise.resolve(JSON.stringify(responseJson))
          });

          return;
        }

        // authorization
        if (
          url.endsWith('/api/authorization/permission/select') &&
          opts.method === 'GET'
        ) {
          resolve({ ok: true, json: () => Promise.resolve(permissions) });

          return;
        }
        // USERS ---------------------------------------------------------------------------
        // get users
        if (url.endsWith('/depen/v1/users') && opts.method === 'GET') {
          users.forEach(function (v) {
            delete v.password;
          });

          resolve({
            ok: true,
            text: () => Promise.resolve(JSON.stringify(users))
          });
        }

        // register user
        if (url.endsWith('/depen/v1/users') && opts.method === 'POST') {
          // get new user object from post body
          const newUser = JSON.parse(opts.body);

          const duplicateUser = users.filter(user => {
            return user.login === newUser.login;
          }).length;
          if (duplicateUser) {
            reject('Usuário "' + newUser.login + '" já em uso');
            return;
          }

          newUser.id = users.length
            ? Math.max(...users.map(user => user.id)) + 1
            : 1;
          users.push(newUser);

          resolve({ ok: true, text: () => Promise.resolve() });

          return;
        }

        // update user
        if (url.endsWith('/depen/v1/users/teste') && opts.method === 'PUT') {
          // get new user object from post body
          const userChanges = JSON.parse(opts.body);
          const user = users.filter(user => {
            return user.login === userChanges.login;
          }).length;
          if (!user) {
            reject('Usuário não existe');
            return;
          }
          for (let i = users.length - 1; i >= 0; i--) {
            if (users[i].login === 'teste') {
              users[i] = userChanges;
            }
          }
          resolve({ ok: true, text: () => Promise.resolve() });

          return;
        }
        // delete user
        if (url.endsWith('/depen/v1/users/teste') && opts.method === 'DELETE') {
          for (let i = users.length - 1; i >= 0; i--) {
            if (users[i].login === 'teste') {
              users.splice(i, 1);
            }
          }

          resolve({ ok: true, json: () => Promise.resolve() });

          return;
        }

        // Transcriptions ---------------------------------------------------------------------------
        // get transcriptions
        if (
          url.endsWith('/api/transcription/search') &&
          opts.method === 'GET'
        ) {
          if (opts.headers) {
            resolve({
              ok: true,
              json: () => Promise.resolve(transcriptions)
            });
          } else {
            reject('Não autorizado');
          }

          return;
        }

        if (url.endsWith('/depen/v1/room') && opts.method === 'GET') {
          if (
            opts.headers &&
            opts.headers.Cookie === 'access_token:jwt-token'
          ) {
            resolve({
              ok: true,
              text: () => Promise.resolve(JSON.stringify(transcriptions[0]))
            });
          } else {
            reject('Não autorizado');
          }

          return;
        }

        realFetch(url, opts).then(response => resolve(response));
      }, 500); // delay
    });
  };
}
