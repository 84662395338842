var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _vm.account.status.loggedIn ? _c("Header") : _vm._e(),
      _c(
        "div",
        { class: [{ collapsed: _vm.collapsed }], attrs: { id: "menu" } },
        [
          _c("router-view"),
          _vm.account.status.loggedIn
            ? _c(
                "sidebar-menu",
                {
                  attrs: {
                    menu: _vm.menu,
                    hideToggle: _vm.hideToogle,
                    collapsed: _vm.collapsed,
                    width: _vm.width,
                  },
                  on: {
                    "toggle-collapse": _vm.onToggleCollapse,
                    "item-click": _vm.onItemClick,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "headerMenu" },
                              [
                                _c("img", {
                                  staticClass: "logo-container",
                                  attrs: {
                                    src: require("@/assets/images/LogoNovo-Retextoar-branco.png"),
                                  },
                                }),
                                _c("b-link", {
                                  staticClass: "title-style",
                                  attrs: { to: { path: "/home" } },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c("div", { attrs: { id: "armored_website" } }, [
                              _c("param", {
                                attrs: { id: "aw_preload", value: "true" },
                              }),
                              _c("param", {
                                attrs: { id: "aw_use_cdn", value: "true" },
                              }),
                            ]),
                            _c(
                              "div",
                              [
                                _c("img", {
                                  staticStyle: {
                                    "{ display": "inline-block",
                                    "margin-bottom": "5px",
                                    "margin-left": "20px !important",
                                  },
                                  attrs: {
                                    src: require("@/assets/icons/Logo-Dgt-Rodape.svg"),
                                    width: "55px",
                                    height: "55px",
                                  },
                                }),
                                _c(
                                  "b-label",
                                  {
                                    staticStyle: {
                                      "{font-family": "Poppins",
                                      "font-size": "12px",
                                      "font-weight": "600",
                                      color: "#e0e0e2 !important",
                                      "margin-left": "30px !important",
                                    },
                                  },
                                  [_vm._v("Ver." + _vm._s(_vm.version))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "dropdown-icon",
                        fn: function () {
                          return [
                            _c("img", {
                              staticStyle: { transform: "rotate(270deg)" },
                              attrs: {
                                src: require("@/assets/icons/arrow-left.svg"),
                                width: "15px",
                                height: "15px",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1626275952
                  ),
                },
                [
                  this.$store.state.alert.hasError
                    ? _c("div", [
                        _c("div", [_vm._v(_vm._s(_vm.showErrorMessage()))]),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }