var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    [
      _c(
        "b-navbar",
        { attrs: { fixed: "top" } },
        [
          _c(
            "b-nav",
            { staticClass: "balance", attrs: { pills: "" } },
            [
              _c(
                "b-link",
                {
                  staticClass: "link font-weight-bold",
                  staticStyle: { "font-size": "15px" },
                  attrs: { id: "size" },
                },
                [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.showModal()
                        },
                      },
                    },
                    [_vm._v("Saldo")]
                  ),
                ]
              ),
              _c(
                "b-modal",
                {
                  ref: "modal",
                  attrs: {
                    id: "modal-prevent-closing",
                    "modal-class": "edit-title",
                    title: _vm.modalTitle,
                    "hide-footer": "",
                  },
                },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "template" },
                      [
                        _c(
                          "b-row",
                          {
                            attrs: {
                              cols: "3",
                              "cols-sm": "3",
                              "cols-md": "4",
                              "cols-lg": "3",
                            },
                          },
                          [
                            _c("b-col", {
                              staticClass: "creditsBalance",
                              staticStyle: {},
                            }),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-2 creditsBalance" },
                              [_vm._v("Aplicativo ")]
                            ),
                            _c("b-col", { staticClass: "creditsBalance" }, [
                              _vm._v("API"),
                            ]),
                            _c("b-col", { staticClass: "creditsBalance" }, [
                              _vm._v("Total "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "creditsBalanceRight" },
                              [_vm._v(_vm._s(_vm.account.creditsTotal))]
                            ),
                            _c(
                              "b-col",
                              { staticClass: "creditsBalanceRight" },
                              [_vm._v(_vm._s(_vm.account.creditsApiTotal))]
                            ),
                            _c("b-col", { staticClass: "creditsBalance" }, [
                              _vm._v("Reservado "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "creditsBalanceRight" },
                              [_vm._v(_vm._s(_vm.account.reserved))]
                            ),
                            _c(
                              "b-col",
                              { staticClass: "creditsBalanceRight" },
                              [_vm._v(_vm._s(_vm.account.reservedApi))]
                            ),
                            _c("b-col", { staticClass: "creditsBalance" }, [
                              _vm._v("Disponível "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "creditsBalanceRight" },
                              [_vm._v(_vm._s(_vm.account.creditsAvailable))]
                            ),
                            _c(
                              "b-col",
                              { staticClass: "creditsBalanceRight" },
                              [_vm._v(_vm._s(_vm.account.creditsApiAvailable))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("b-table", {
                      staticClass: "text-primary",
                      staticStyle: {
                        "{ max-height": "300px !important",
                        height: "300px !important",
                        "overflow-y": "scroll}",
                      },
                      attrs: {
                        fields: _vm.statementsCreditFields,
                        items: _vm.statementsCreditItems,
                        responsive: "",
                        striped: "",
                      },
                    }),
                  ],
                  _c(
                    "footer",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function ($event) {
                              return _vm.hideModal()
                            },
                          },
                        },
                        [_vm._v("Fechar")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "b-link",
                { staticClass: "link", staticStyle: { "font-size": "12px" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text_balance",
                      on: {
                        click: function ($event) {
                          return _vm.showModal()
                        },
                      },
                    },
                    [_vm._v("Visualizar")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "logged-user" },
            [
              _c(
                "b-nav",
                { attrs: { pills: "" } },
                [
                  _c(
                    "b-nav-item-dropdown",
                    { staticClass: "drop-menu", attrs: { right: "" } },
                    [
                      _c("template", { slot: "button-content" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/icons/account-circle.svg"),
                          },
                        }),
                        _c("span", { staticClass: "user-name" }, [
                          _vm._v(
                            _vm._s(
                              " " + this.$store.state.account.user.username
                            ) + " "
                          ),
                        ]),
                      ]),
                      _vm.account.master
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: { id: "admin-item" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push("/admin")
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "drop-menu-items" }, [
                                _c("img", {
                                  attrs: {
                                    id: "profile-button",
                                    src: require("@/assets/icons/admin_panel_settings_black_24dp.svg"),
                                  },
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-family": "Poppins",
                                      "font-size": "14px",
                                      "font-weight": "600",
                                      color: "#6278A3",
                                      "padding-top": "16px",
                                      "padding-left": "10px",
                                    },
                                  },
                                  [_vm._v(" Admin")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "drop-menu-items",
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/profile")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "drop-menu-items" }, [
                            _c("img", {
                              attrs: {
                                id: "profile-button",
                                src: require("@/assets/icons/manage_accounts_black_24dp.svg"),
                              },
                            }),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-family": "Poppins",
                                  "font-size": "14px",
                                  "font-weight": "600",
                                  color: "#6278A3",
                                  "padding-top": "16px",
                                  "padding-left": "10px",
                                },
                              },
                              [_vm._v(" Conta")]
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          staticClass: "drop-menu-items",
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/login")
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "drop-menu-items" }, [
                            _c("img", {
                              staticStyle: {
                                "padding-right": "15px",
                                width: "48px",
                              },
                              attrs: {
                                id: "exit-button",
                                src: require("@/assets/icons/u_sign-out-alt.svg"),
                              },
                            }),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-family": "Poppins",
                                  "font-size": "14px",
                                  "font-weight": "600",
                                  color: "#6278A3",
                                  "padding-top": "16px",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(" Sair")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }